import { Link } from "react-router-dom";
import { BsMoonFill, BsFillSunFill } from "react-icons/bs";
import logo from "../../assets/ds-b-2500/ds-b-logo.png";
import darklogo from "../../assets/ds-w-500/ds-logo.png";
import { FiMenu } from "react-icons/fi";
import { GrClose } from "react-icons/gr";

import React, { useState } from "react";

function Header(props) {
  const [open, setOpen] = useState(false);
  const MobileNav = { true: "translateX(0%)", false: "translateX(-100%)" };
  var style = {
    transform: MobileNav[open],
  };
  return (
    <nav className="container-wrapper flex relative items-center justify-between  py-2 lg:py-3  dark:bg-[#202124] dark:text-white px-3">
      <div className="flex  items-center  ">
        <div>
          <Link to="/" className="w-full">
            <img
              className="w-20"
              src={props.toggleDark ? darklogo : logo}
              alt="logo"
            />
          </Link>
        </div>

        <h1 className="hidden md:block lg:pr-24 2xl:pr-0 pl-5 montserrat font-semibold tracking-[10px] text-[35px] lg:text-2xl text-brand_gray dark:text-white">
          DIMENSIONLESS
        </h1>
      </div>
      <div className="hidden lg:block w-6/12">
        <ul className="flex items-center justify-end mr-16">
          <li>
            <div className="text-[#707070] cursor-pointer text-sm lg:text-lg 2xl:text-4xl mr-4">
              {!props.toggleDark ? (
                <BsMoonFill onClick={() => props.handleModeChange()} />
              ) : (
                <BsFillSunFill onClick={() => props.handleModeChange()} />
              )}
            </div>
          </li>
          <li>
            <Link to="/explore">
              <p className="px-4 text-[#707070] text-sm lg:text-base 2xl:text-2xl font-semibold">
                Explore
              </p>
            </Link>
          </li>
          <li>
            <Link to="/community">
              <p className="px-4  text-[#707070] text-sm lg:text-base 2xl:text-2xl font-semibold">
                Community
              </p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="lg:hidden w-2/12 text-4xl flex justify-end ">
        <div>
          <FiMenu onClick={() => setOpen(!open)} />
        </div>
      </div>
      <aside
        style={style}
        className="fixed left-0 right-0 top-0 bottom-0 z-50 min-h-screen bg-gray-800 lg:hidden bg-[#707070]  dark:bg-[#464646]"
      >
        <div className="pages border-r pr-3">
          <div className="lg:hidden py-4 text-4xl flex justify-end items-end ">
            <div>
              <GrClose onClick={() => setOpen(!open)} />
            </div>
          </div>

          <ul className="block items-center justify-end">
            <li>
              <div className="lg:text-[#707070] dark:text-white text-black  p-6 cursor-pointer text-2xl lg:text-xl 2xl:text-4xl mr-4">
                {!props.toggleDark ? (
                  <BsMoonFill onClick={() => props.handleModeChange()} />
                ) : (
                  <BsFillSunFill onClick={() => props.handleModeChange()} />
                )}
              </div>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link to="/explore">
                <p className="px-4 lg:text-[#707070] dark:bg- dark:text-white text-black p-6 text-2xl  lg:text-xl 2xl:text-4xl font-semibold">
                  Explore
                </p>
              </Link>
            </li>
            <li onClick={() => setOpen(false)}>
              <Link to="/community">
                <p className="px-4  lg:text-[#707070] dark:text-white text-black text-2xl lg:text-xl 2xl:text-4xl font-semibold">
                  Community
                </p>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </nav>
  );
}
export default Header;
