import people from "../../../assets/images/people.jpg";
import review1 from "../../../assets/HomePage/review1.png";
import review2 from "../../../assets/HomePage/review2.png";
import review3 from "../../../assets/HomePage/review3.png";
import reviews from "../../../assets/HomePage/reviews.png";
import rating from "../../../assets/HomePage/rating.png";
import fb from "../../../assets/HomePage/fb.png";
import fbReview from "../../../assets/HomePage/fbReview.png";
import justdail from "../../../assets/HomePage/justdail.png";
import justdailRating from "../../../assets/HomePage/justdailRating.png";

function Review() {
  return (
    <section className="container-wrapper dark:bg-[#202124] dark:text-white px-3 py-10 md:px-10 ">
      <div className="text-center font-semibold text-3xl 2xl:text-5xl py-6">
        Testimonials
      </div>

      <div className="flex justify-around md:justify-between items-center lg:py-16">
        <div className="w-1/4 lg:w-2/12  ">
          <img className="" src={reviews} />
        </div>

        <div className=" w-1/2 lg:w-2/3 pt-4 text-center md:ml-40">
          <img src={rating} />
        </div>
      </div>

      <div className="lg:px-10 lg:py-2 px-2">
        <div className="py-5 lg:px-4 lg:py-6">
          <img src={review1} />
        </div>
        <div className="py-5 lg:px-4 lg:py-10 ">
          <img src={review2} />
        </div>
        <div className="py-5 lg:px-4 lg:py-6">
          <img src={review3} />
        </div>
      </div>

      <div className="dark:text-[#8AB4F8] text-[#1A73E8] py-6 lg:py-20 flex justify-between text-4xl 2xl:text-6xl   ">
        <img className="w-1/12 px-1 mx-2" src={fb} alt="Metting image" />
        <img className="w-3/12 px-1 mx-2" src={fbReview} alt="Metting image" />
        <img className="w-3/12 px-1 mx-2" src={justdail} alt="Metting image" />
        <img
          className="w-3/12 px-1 mx-2"
          src={justdailRating}
          alt="Metting image"
        />
      </div>
    </section>
  );
}
export default Review;
