export let data = [
  {
    id: 1,
    img: require("../../../assets/art/vertical/IMG_1834.webp"),
    price: "25,000",
  },
  {
    id: 2,
    img: require("../../../assets/art/vertical/IMG_1838.webp"),
    price: "25,000",
  },
  {
    id: 3,
    img: require("../../../assets/art/vertical/IMG_1839.webp"),
    price: "10,000",
  },
  {
    id: 4,
    img: require("../../../assets/art/vertical/IMG_1840.webp"),
    price: "20,000",
  },
  {
    id: 5,
    img: require("../../../assets/art/vertical/IMG_1841.webp"),
    price: "12,000",
  },
  {
    id: 6,
    img: require("../../../assets/art/vertical/IMG_1842.webp"),
    price: "12,000",
  },
  {
    id: 7,
    img: require("../../../assets/art/vertical/IMG_1900.webp"),
    price: "12,000",
  },
  {
    id: 8,
    img: require("../../../assets/art/vertical/IMG_1901.webp"),
    price: "12,000",
  },

  {
    id: 9,
    img: require("../../../assets/art/vertical/IMG_1902.webp"),
    price: "5,000",
  },
  {
    id: 10,
    img: require("../../../assets/art/vertical/IMG_1903.webp"),
    price: "10,000",
  },
  {
    id: 11,
    img: require("../../../assets/art/vertical/IMG_1904.webp"),
    price: "12,000",
  },
  {
    id: 12,
    img: require("../../../assets/art/vertical/IMG_1906.webp"),
    price: "5,000",
  },
  {
    id: 13,
    img: require("../../../assets/art/vertical/IMG_1907.webp"),
    price: "12,000",
  },
  {
    id: 14,
    img: require("../../../assets/art/vertical/IMG_1908.webp"),
    price: "10,000",
  },
  {
    id: 15,
    img: require("../../../assets/art/vertical/IMG_1909.webp"),
    price: "15,000",
  },
  {
    id: 16,
    img: require("../../../assets/art/vertical/IMG_1910.webp"),
    price: "15,000",
  },
  {
    id: 17,
    img: require("../../../assets/art/vertical/IMG_1913.webp"),
    price: "8,000",
  },
  {
    id: 18,
    img: require("../../../assets/art/vertical/IMG_1914.webp"),
    price: "8,000",
  },
  {
    id: 19,
    img: require("../../../assets/art/vertical/IMG_1917.webp"),
    price: "8,000",
  },

  {
    id: 20,
    img: require("../../../assets/art/vertical/IMG_1918.webp"),
    price: "8,000",
  },
  {
    id: 21,
    img: require("../../../assets/art/vertical/IMG_1919.webp"),
    price: "8,000",
  },
  {
    id: 22,
    img: require("../../../assets/art/vertical/IMG_1920.webp"),
    price: "8,000",
  },
  {
    id: 23,
    img: require("../../../assets/art/vertical/IMG_1921.webp"),
    price: "8,000",
  },
  {
    id: 24,
    img: require("../../../assets/art/vertical/IMG_1838.webp"),
    price: "20,000",
  },
  {
    id: 25,
    img: require("../../../assets/art/vertical/IMG_1923.webp"),
    price: "12,000",
  },
  {
    id: 26,
    img: require("../../../assets/art/vertical/IMG_1924.webp"),
    price: "8,000",
  },
  {
    id: 27,
    img: require("../../../assets/art/vertical/IMG_1924.webp"),
    price: "8,000",
  },
  {
    id: 28,
    img: require("../../../assets/art/Horizontal/IMG_1846.webp"),
    price: "30,000",
  },
  {
    id: 29,
    img: require("../../../assets/art/Horizontal/IMG_1848.webp"),
    price: "7,000",
  },

  {
    id: 30,
    img: require("../../../assets/art/Horizontal/IMG_1849.webp"),
    price: "7,000",
  },
  {
    id: 31,
    img: require("../../../assets/art/Horizontal/IMG_1850.webp"),
    price: "5,000",
  },
  {
    id: 32,
    img: require("../../../assets/art/Horizontal/IMG_1851.webp"),
    price: "5,000",
  },
  {
    id: 33,
    img: require("../../../assets/art/Horizontal/IMG_1852.webp"),
    price: "6,000",
  },
  {
    id: 34,
    img: require("../../../assets/art/Horizontal/IMG_1853.webp"),
    price: "6,000",
  },
  {
    id: 35,
    img: require("../../../assets/art/Horizontal/IMG_1854.webp"),
    price: "6,000",
  },
  {
    id: 36,
    img: require("../../../assets/art/Horizontal/IMG_1894.webp"),
    price: "15,000",
  },
  {
    id: 37,
    img: require("../../../assets/art/Horizontal/IMG_1897.webp"),
    price: "15,000",
  },
  {
    id: 38,
    img: require("../../../assets/art/Horizontal/IMG_1899.webp"),
    price: "5,000",
  },
];
