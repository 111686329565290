import { Link } from "react-router-dom";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import logo from "../../assets/ds-w-500/ds-logo.png";
import darklogo from "../../assets/ds-b-2500/ds-b-logo.png";

function Footer(props) {
  return (
    <footer className="dark:bg-[#8AB4F8] dark:text-white bg-[#4D93EF]  px-5 md:px-10 ">
      <div className="container-wrapper">
        <div className=" md:flex justify-between items-start dark:border-[#464646] border-b-2 border-[#EEEEEE]  py-6 lg:py-20">
          <div className="w-full md:w-2/3">
            <h3 className="dark:text-black text-[#FFFFFF] montserrat text-2xl lg:text-3xl  font-semibold ">
              Get Updates
            </h3>
            <p className="w-2/4 dark:text-[#464646] text-[#E3E3E3] text-base py-6 2xl:text-2xl">
              Get the latest buzz, we can now stay connected.
            </p>
            <div className="md:flex ">
              <div className="w-full ">
                <input
                  className="dark:bg-[#464646] rounded-[10px] py-2 lg:py-4 px-2 lg:px-8 text:sm lg:text-xl 2xl:px-20 montserrat w-full"
                  type="email"
                  placeholder="Your Email Address"
                />
              </div>

              <div className="w-full md:w-2/5 py-4 md:py-0">
                <button className="md:ml-5 bg-[#1A73E8] montserrat text-[#FFFFFF] rounded-[10px] py-2 lg:py-4 px-2 montserrat w-full text:sm lg:text-xl">
                  SIGN UP
                </button>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/12 flex flex-col justify-end">
            <h3 className="dark:text-black montserrat text-[#FFFFFF]  font-semibold text-2xl lg:3xl py-4 md:py-0 ">
              Join Us Now
            </h3>
            <div className=" dark:text-[#464646] text-[#FFF] flex py-6 lg:text-lg 2xl:text-2xl items-center">
              <div className=" mr-2 p-2 bg-white  dark:bg-[#464646] rounded-lg">
                <BsTwitter color="#1A73E8" />
              </div>

              <div className="  mr-2 p-2 bg-white dark:bg-[#464646] rounded-lg">
                <a href="https://www.facebook.com/dimensionless.art">
                  <FaFacebookSquare color="#1A73E8" />
                </a>
              </div>

              <div className=" mr-2 p-2 bg-white dark:bg-[#464646] rounded-lg">
                <a href="https://www.instagram.com/dimensionless.art.studio">
                  <BsInstagram color="#1A73E8" />
                </a>
              </div>

              <div className="  mr-2 p-2 bg-white  dark:bg-[#464646] rounded-lg">
                <a href="https://www.youtube.com/channel/UCJeIjZBpJbOmCUVqtOq6NjQ">
                  <BsYoutube color="#1A73E8" />
                </a>
              </div>
              <div className=" mr-2 p-2 bg-white dark:bg-[#464646] rounded-lg">
                <HiOutlineMail color="#1A73E8" />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 pt-10">
          <div className=" ">
            <h5 className="dark:text-black text-white text-lg md:text-2xl  font-semibold mb-6">
              Categories
            </h5>
            <div className="dark:text-[#464646] text-[#E3E3E3] text-base md:text-xl md:pt-2">
              <div className="my-2">
                <Link to="/Tattoos" className="my-10">
                  Tattoos
                </Link>
              </div>

              <div className="my-2">
                <Link to="/art">Art</Link>
              </div>
              <div className="my-2">
                <Link to="/craft">Craft</Link>
              </div>
              <div className="my-2">
                <Link to="/mindfulness">Mindfulness</Link>
              </div>
              <div className="my-2">
                <Link to="/books">Books & Beyond</Link>
              </div>
              <div className="my-2">
                <Link to="/workshop">Workshops</Link>
              </div>
              <div className="my-2">
                <Link to="/training">Trainings</Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div>
              <h5 className="dark:text-black text-white text-lg md:text-2xl  font-semibold mb-6">
                Community
              </h5>
              <div className="dark:text-[#464646] text-[#E3E3E3] text-base md:text-xl md:pt-2">
                <div className="my-2">
                  <Link to="/community">DNA Community </Link>
                </div>
                <div className="my-2">Blogs</div>
              </div>
            </div>
          </div>
          <div className="flex md:justify-end">
            <div className="md:pt-0 pt-10">
              <h5 className="dark:text-black text-white text-lg md:text-2xl font-semibold mb-6">
                Company
              </h5>
              <div className="dark:text-[#464646] text-[#E3E3E3] text-base md:text-xl md:pt-2 ">
                <div className="my-2">
                  <Link to="/about-us">About Us</Link>
                </div>
                <div className="my-2">
                  <Link to="/contact-us">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-b-2 dark:border-[#464646] border-[#EEEEEE]  ">
          <div className=" flex flex-wrap justify-center item-center">
            <img
              className="w-20 md:w-48 "
              src={props.toggleDark ? darklogo : logo}
              alt="logo"
            />
          </div>
          <h2
            className="dark:text-black flex flex-wrap justify-center text-2xl montserrat text-center text-[#FFFFFF] 
        tracking-widest py-4 md:py-4 2xl:text-4xl"
          >
            DIMENSIONLESS
          </h2>
        </div>
        <div className="dark:text-[#464646] text-[#E3E3E3] py-6 text-sm 2xl:text-lg flex justify-between ">
          <div>Site Map</div>
          <div className="">Terms & Conditions</div>
          <div> Privacy Policy</div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
