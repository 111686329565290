import art from "../../../assets/art-03.svg";
function Banner() {
  return (
    <>
      <div className="dark:bg-[#202124]  bg-white block container-wrapper md:flex item-center justify-between  px-2 md:px-14 md:py-14">
        <div className="w-full md:w-2/5  ">
          <div className="hidden lg:block text-base text-blue-grey-600 my-2 lg:py-4 font-[Montserrat]">
            &lt; Explore
          </div>
          <div
            className="dark:text-white text-[#343434] font-[Calvino] font-bold text-2xl text-center md:text-left md:text-5xl  
          2xl:text-[70px] py-6 md:py-4"
          >
            <h2>Art</h2>
          </div>
          {/* <p className="dark:text-[#B5B5B5] montserrat px-0 py-2  md:pt-8 text-[#343434] text-center lg:text-left lg:text-2xl 2xl:text-3xl">
            Lorem ipsum dolor sit amet, consetetur sadipscing
          </p> */}
          <div className="py-6 lg:py-24 text-center md:text-left  ">
            <button className="dark:bg-[#8AB4F8] dark:text-black button-shadow rounded-lg bg-[#1A73E8] text-[#FFFFFF]  py-3 text-sm montserrat  lg:rounded-[20px] px-8  2xl:text-2xl">
              Book an Order Now
            </button>
          </div>
        </div>
        <div className="flex items-end justify-center w-full md:w-3/5 ">
          <img className="w-full  " src={art} alt="artImage" />
        </div>
      </div>
    </>
  );
}
export default Banner;
