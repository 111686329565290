import Banner from "./banner";
import Artwork from "./artwork";
import Review from "./review";
import Form from "./order";
function Art() {
  return (
    <div className="">
      <Banner />
      <Artwork />
      <Review />
      <Form />
    </div>
  );
}
export default Art;
