import formimg from "../../../assets/art/Group1759.svg";
function Form() {
  return (
    <section className="darkmode bg-[#EEEEEE] pb-10 md:pb-16 2xl:pb-24 p-6 px-0 md:px-4 lg:px-10">
      <div className="darkmode bg-[#EEEEEE] grid md:grid-cols-2  items-center container-wrapper ">
        <div className="hidden md:grid justify-center items-center  px-3">
          <img src={formimg} />
        </div>
        <div className=" flex justify-between flex-col   px-3 ">
          <h4 className="text-xl text-center md:text-4xl 2xl:text-5xl font-semibold py-8">
            Order Now
          </h4>
          <div className="dark:bg-[#202124] bg-white w-full py-8 rounded-xl lg:rounded-2xl   px-3 lg:px-8">
            <div className="pb-10">
              <div className="py-3">
                <input
                  className="dark:bg-[#464646] w-full rounded-lg   bg-[#EEEEEE] py-4 px-2"
                  placeholder="Name"
                  type="text"
                  id="name"
                />
              </div>
              <div className="py-3 ">
                <input
                  className="dark:bg-[#464646] w-full rounded-lg   bg-[#EEEEEE] py-4 px-2 "
                  placeholder="E-mail Address"
                  type="email"
                  id="e-mail"
                />
              </div>
              <div className="py-3">
                <input
                  className="dark:bg-[#464646] w-full rounded-lg   bg-[#EEEEEE] py-4 px-2"
                  placeholder="Contact Number"
                  type="text"
                  id="number"
                />
              </div>
              <div className="py-3">
                <textarea
                  className="dark:bg-[#464646] w-full rounded-lg   bg-[#EEEEEE] py-4 px-2"
                  placeholder="Get a Fresh Art ready from our Skilled Artist"
                  type="textbox"
                  id="date"
                />
              </div>
            </div>

            <div className="flex justify-center py-8 items-center">
              <button className="dark:bg-[#8AB4F8] dark:text-black bg-[#1A73E8] shadow montserrat text-white rounded-xl px-4 md:px-14 2xl:px-20 py-2  text-xl 2xl:text-2xl ">
                Contact Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Form;
