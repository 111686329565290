import art1 from "../../../assets/images/art1.jpg";
import art2 from "../../../assets/images/art2.png";
import art3 from "../../../assets/images/art3.jpg";
import { BiRupee } from "react-icons/bi";
import { Link } from "react-router-dom";
import { data } from "../../art/artwork/data";
function Trending() {
  return (
    <section className=" dark:bg-[#202124] p-6 lg:py-16 2xl:py-20 lg:px-40">
      <div className="  flex flex-wrap justify-center text-center items-center 2xl:py-10">
        <h2 className="font-semibold text-3xl 2xl:text-5xl py-6">
          Trending ART
        </h2>
      </div>

      <div className="grid md:grid-cols-3 lg:grid-cols-3  gap-8 px-6 md:px-10 ">
        {data.map((item, ind) => {
          if (item.id <= 3)
            return (
              <div key={ind}>
                <img className="rounded-t-2xl  " src={item.img} alt="image" />

                <div className="darkmode bg-[#EEEEEE]  text-black flex text-center justify-center items-center montserrat  lg:text-2xl 2xl:text-3xl py-3 rounded-b-2xl">
                  {/* <div className="darkmode bg-[#EEEEEE] p-2 text-black flex items-center text-base text-center"> */}
                  <BiRupee />
                  {item.price}
                  {/* </div> */}
                </div>
              </div>
            );
        })}
      </div>
      {/* <div className=" pt-8 w-full flex flex-wrap justify-center lg:justify-between items-center text-center ">
        <div className=" w-8/12 lg:w-3/12  text-center  rounded-3xl overflow-hidden ">
          <img src={art1} />
          <div className=" darkmode bg-[#EEEEEE] flex montserrat justify-center items-center 2xl:text-3xl ">
            <div>
              <BiRupee />
            </div>
            <p className=" darkmode bg-[#EEEEEE] p-2 2xl:py-6 text-black">
              1000
            </p>
          </div>
        </div>
        <div className=" w-8/12 lg:w-3/12 mt-6 lg:mt-0 text-center  rounded-3xl overflow-hidden ">
          <div>
            <img src={art2} />
            <div className=" darkmode bg-[#EEEEEE] flex montserrat justify-center items-center 2xl:text-3xl ">
              <div>
                <BiRupee />
              </div>
              <p className="darkmode bg-[#EEEEEE] p-2 2xl:py-6 text-black">
                1000
              </p>
            </div>
          </div>
        </div>
        <div className=" w-8/12 lg:w-3/12 mt-6 lg:mt-0 text-center  rounded-3xl overflow-hidden ">
          <div>
            <img src={art2} />
            <div className="darkmode bg-[#EEEEEE] flex montserrat justify-center items-center 2xl:text-3xl ">
              <div>
                <BiRupee />
              </div>
              <p className="darkmode bg-[#EEEEEE] p-2 2xl:py-6 text-black">
                1000
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="flex justify-center pt-8  lg:pt-10 2xl:pt-16">
        <Link to="/art">
          <button className="dark:bg-[#8AB4F8] dark:text-black button-shadow rounded-lg 2xl:rounded-2xl bg-[#1A73E8] text-[#FFFFFF] px-10 py-3 text-sm montserrat lg:px-8 lg:py-2 2xl:px-14 2xl:py-3 2xl:text-3xl ">
            Explore MORE ART
          </button>
        </Link>
      </div>
    </section>
  );
}
export default Trending;
