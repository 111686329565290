import { Link } from "react-router-dom";
import Banner from "./banner";
import Upcoming from "./up-training";
function Training(){
    return(
        <div>
           <Banner/>
           <Upcoming/>
        </div>
    )
}
export default Training