import { BiRupee } from "react-icons/bi";
import { data } from "../artwork/data";
function Artwork() {
  return (
    <section className="container-wrapper dark:bg-[#202124] dark:text-white pb-8 px-2 ">
      <h2 className=" font-base text-3xl 2xl:text-[60px] text-center md:py-16 py-20">
        ARTWORK
      </h2>

      <div className="grid md:grid-cols-2 lg:grid-cols-3  gap-8 px-6 md:px-10 ">
        {data.map((item, ind) => {
          return (
            <div key={ind}>
              <img className="rounded-t-2xl  " src={item.img} alt="image" />

              <div className="darkmode bg-[#EEEEEE] flex montserrat justify-between items-center py-3 rounded-b-2xl">
                <div className="darkmode bg-[#EEEEEE] p-2 text-black flex items-center text-base lg:text-2xl">
                  <BiRupee />
                  {item.price}
                </div>
                <div className="px-4 ">
                  <button
                    className="bg-white  dark:bg-[#464646] button-shadow text-[#1A73E8] rounded-xl py-1.5 px-2 border-2 border-[#1A73E8] 
                  text-sm lg:text-xl hover:bg-[#1A73E8] hover:text-white"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
export default Artwork;
