import { Link } from "react-router-dom";
import Banner from "./banner";
import About from "./about";
function Community(){
    return(
        <div>
          <Banner/>
          <About/>
        </div>
    )
}
export default Community