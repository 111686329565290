import { Link } from "react-router-dom";
import Banner from "./banner";
import Upcoming from "./upcoming";
function Workshop(){
    return(
        <div>
        <Banner/>
        <Upcoming/>
        </div>
    )
}
export default Workshop