import { Link } from "react-router-dom";
import Banner from "./banner";
import Form from "./form";
import Map from "./map";
function Contact(){
    return(
        <div>
         <Banner/>
         <Form/>
         <Map/>
        </div>
    )
}
export default Contact